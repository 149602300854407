import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { CanalContext } from "./provider/CanalProvider";
import { getCanal, getClientesPorCanal } from "../../utils/queryvisitas";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MapView from "./MapView";
import MapInfo from "./MapInfo";
import InfoFullGrid from "./InfoFullGrid";
import MapDetail from "./MapDetail";
import MapSellersGroup from "./MapSellersGroup";
import { URL_BASE } from "../../config";


const style = { height: "calc(100vh - 70px)" };

export default function Canales() {
  const { full } = useContext(CanalContext);
  // const { urlCanal } = useParams();

  return (
    <>
      <MapDetail />
      <MapSellersGroup />
      <Box sx={{ ...style, display: "flex" }}>
        {full ? (
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <InfoFullGrid />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={0.5}>
            <Grid item xs={6}>
              <MapInfo />
            </Grid>
            <Grid item xs={6}>
              <MapView />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
