import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../provider/DataProvider";
import "./StatsSales.css";

export default function StatsSales() {
    const { sales } = useContext(DataContext);
    const [estadisticas, setEstadisticas] = useState({compra: '',nocompra: '',pendiente: ''});
    useEffect(() => {
        const resultado = sales.reduce((acum,actual) => {
            if (actual.status === "Compra") { 
                return {...acum, compra: parseInt(acum.compra)+1}
            } else if (actual.status === "No Visitado") {
                return {...acum, pendiente: parseInt(acum.pendiente)+1}
            } else {
                return {...acum, nocompra: parseInt(acum.nocompra)+1}
            }        
        }
        , {compra: 0, nocompra: 0, pendiente: 0}
        );
        setEstadisticas(resultado);

    }, [sales])


  return (
    <div className="container-stats">
        <div className="button button-green">{estadisticas.compra}</div> 
        <div className="button button-red">{estadisticas.nocompra}</div> 
        <div className="button button-blue">{estadisticas.pendiente}</div> 
    </div>
  )
}
