import { useContext, useState, useRef } from "react";
import { CanalContext } from "../provider/CanalProvider";
import { getClientesPorCanal } from "../../../utils/queryvisitas";
import Button from "@mui/material/Button";
import { URL_BASE } from "../../../config";

export default function BtnBuscar() {
  const { setVisitas, codigoFecha, canalSelected, setTotales } = useContext(CanalContext);
 let visitasPrimera = useRef([]);
 let pedidosPrimera = useRef([]);
 let totalesPrimera = useRef([]);

  function buscar() {

    //////////////////////////////// LA RUTA ////////////////////////////////
    const laRuta = getClientesPorCanal(codigoFecha.fecha, JSON.parse(canalSelected.vendedores).toString().replace(/,/g, "-"))
      .then(clientes => {
        const resumenConsolidado = clientes?.reduce((acumulador, actual) => {
          let indexAct = acumulador.findIndex(a => a.codigo === actual.codigo);
          if (indexAct === -1) {
            return [...acumulador, { codigo: actual.codigo, clientes: 1, compra: 0, nocompra: 0, pendiente: 0, pedidos: 0, tneto: 0, tkilos: 0, pneto: 0, pkilos: 0 }]
          } else {
            acumulador[indexAct].clientes++
            return acumulador
          }
        }, [])
        return resumenConsolidado
      })

    //////////////////////////////// LAS VISITAS ////////////////////////////////
    const visitasEvents = new EventSource(`${URL_BASE}/api/ssecanal/${codigoFecha.fecha}/${canalSelected.nombre}`);

    const laVisita = new Promise((resolve, reject) => {
    visitasEvents.onmessage = (e) => {
      const parsedDataVisitas = JSON.parse(e.data);
      if (parsedDataVisitas.length >= 0) {
        setVisitas(parsedDataVisitas)
        visitasPrimera.current = parsedDataVisitas
        resolve(parsedDataVisitas);
      }
      else {
        // funcion recibe visita (1 visita)
        let lasVisitas = (visitasPrimera.current).map(x => x);
        let copiaDeTotales = (totalesPrimera.current).map(x => x);

        copiaDeTotales.forEach(c=>{
          c.compra = 0;
          c.nocompra = 0;
          c.pendiente = 0;
        })

        const indexVisita = lasVisitas.findIndex(v => parsedDataVisitas.rutcliente === v.rutcliente && parsedDataVisitas.local === v.local)
        const nuevaVisita = {
          secuencia: parsedDataVisitas.secuencia, lat_visita: parsedDataVisitas.lat,
          lng_visita: parsedDataVisitas.lng, status: parsedDataVisitas.status, comment: parsedDataVisitas.comment,
          fecha_visita: parsedDataVisitas.fecha, hora_visita: parsedDataVisitas.hora, nvisita: parsedDataVisitas.nvisita,
          coordOk: parsedDataVisitas.coordOk, fecha_gps: "", hora_gps: "", lat_gps: "", lng_gps: "", source: "",
          imagepath: "",
        }
        lasVisitas[indexVisita].visita.push(nuevaVisita);
        setVisitas(lasVisitas)
        lasVisitas.forEach(v => {
          let ultimaVisita = (v.visita).slice(-1)[0];
          let visitaConCodigo = { ...ultimaVisita, codigo: v.codigo }
          let indiceEnConsolidado = copiaDeTotales.findIndex(a => a.codigo === v.codigo);
          let codigoEnConsolidado = copiaDeTotales[indiceEnConsolidado];
          if (visitaConCodigo.status === "Compra") {
            codigoEnConsolidado.compra = codigoEnConsolidado.compra + 1
          }
          else if (visitaConCodigo.status === "No Visitado") {
            codigoEnConsolidado.pendiente = codigoEnConsolidado.pendiente + 1
          }
          else {
            codigoEnConsolidado.nocompra = codigoEnConsolidado.nocompra + 1
          }
        })

        console.log("copiaDeTotales 79", copiaDeTotales)
        setTotales(copiaDeTotales);
      }
    }
  });

    //////////////////////////////// LOS PEDIDOS ////////////////////////////////
    const pedidosEvents = new EventSource(`${URL_BASE}/api/ssepedidos/${codigoFecha.fecha}/${canalSelected.nombre}`);

    const elPedido = new Promise((resolve, reject) => {
      
      pedidosEvents.onmessage = (e) => {

        const parsedDataPedidos = JSON.parse(e.data);
        if (parsedDataPedidos.length >= 0) {
          // funcion recibe la primera vez
          pedidosPrimera.current = parsedDataPedidos
          resolve(parsedDataPedidos) 
        }
        else {

        let losPedidos = (pedidosPrimera.current).map(x => x);
        let copiaDeTotales = (totalesPrimera.current).map(x => x);
          losPedidos.push(parsedDataPedidos)

        copiaDeTotales.forEach(c=>{
          c.pedidos = 0;
          c.tneto = 0;
          c.tkilos = 0;
          c.pneto = 0;
          c.pkilos = 0;
        })

        losPedidos.forEach(v => {
          const indiceEnConsolidado = copiaDeTotales.findIndex(a => a.codigo === v.codigo);

          if (indiceEnConsolidado >= 0) {
          let codigoEnConsolidado = copiaDeTotales[indiceEnConsolidado];
          codigoEnConsolidado.pedidos++;
          codigoEnConsolidado.tneto = codigoEnConsolidado.tneto + v.Neto;
          codigoEnConsolidado.tkilos = codigoEnConsolidado.tkilos + v.Kilos;
          codigoEnConsolidado.pneto = codigoEnConsolidado.tneto / codigoEnConsolidado.pedidos;
          codigoEnConsolidado.pkilos = codigoEnConsolidado.tkilos / codigoEnConsolidado.pedidos;
        }

        })

        console.log("copiaDeTotales 127", copiaDeTotales)
        setTotales(copiaDeTotales);
      }
        }
    });

    //////////////////////////////// RESUELVE LAS PROMESAS ////////////////////////////////
    Promise.all([laRuta, laVisita, elPedido])
      .then((resultado) => {
        // [resultado-laRuta, resultado-laVisita, resultado-elPedido]
        console.log("laRuta, laVisita, elPedido >>>", resultado);
        return resultado;
      })
      .then(resultado=>{
      // Funcion Actualizar
      let copiaDeTotales = (resultado[0]).map(x => x);
      // Totalizo las visitas
      (resultado[1]).forEach(v => {
        let ultimaVisita = (v.visita).slice(-1)[0];
        let visitaConCodigo = { ...ultimaVisita, codigo: v.codigo }
        let indiceEnConsolidado = copiaDeTotales.findIndex(a => a.codigo === v.codigo);
        let codigoEnConsolidado = copiaDeTotales[indiceEnConsolidado];
        if (visitaConCodigo.status === "Compra") {
          codigoEnConsolidado.compra = codigoEnConsolidado.compra + 1
        }
        else if (visitaConCodigo.status === "No Visitado") {
          codigoEnConsolidado.pendiente = codigoEnConsolidado.pendiente + 1
        }
        else {
          codigoEnConsolidado.nocompra = codigoEnConsolidado.nocompra + 1
        }
      })
      
      return {copiaDeTotales, resultado: resultado[2]}
      })
      .then(({copiaDeTotales, resultado}) =>{
       // Totalizo pedidos + visitas que viene en copiaDeTotales

       resultado.forEach(p=>{

        const indiceEnConsolidado = copiaDeTotales.findIndex(cadaVendedor => cadaVendedor.codigo === p.codigo);

        if (indiceEnConsolidado >= 0) {
 
          let codigoEnCopiaDeTotales = copiaDeTotales[indiceEnConsolidado];

          codigoEnCopiaDeTotales.pedidos++;
          codigoEnCopiaDeTotales.tneto = codigoEnCopiaDeTotales.tneto + p.Neto;
          codigoEnCopiaDeTotales.tkilos = codigoEnCopiaDeTotales.tkilos + p.Kilos;
          codigoEnCopiaDeTotales.pneto = codigoEnCopiaDeTotales.tneto / codigoEnCopiaDeTotales.pedidos;
          codigoEnCopiaDeTotales.pkilos = codigoEnCopiaDeTotales.tkilos / codigoEnCopiaDeTotales.pedidos;
        }

       })

       return copiaDeTotales

      })
      .then(copiaDeTotales=>{
        totalesPrimera.current = copiaDeTotales
        setTotales(copiaDeTotales);
      })
      .catch((error) => {
        console.error(error);
      });
  }

 return (
    <Button onClick={buscar} variant="contained" sx={{ my: 1, marginLeft: "auto" }}>
      Buscar
    </Button>
  )
}
