import { useState } from 'react';
import { createContext } from 'react';

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
	
	const [user, setUser] = useState(null);

	const login = (userCredentials) => {
		console.log('userCredentials>>>', userCredentials)	
		setUser(userCredentials);
	};

	const logout = () => setUser(null);

	const isLogged = () => !!user;

	const contextValue = {
		user,
		isLogged,
		login,
		logout,
	};

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}