import axios from "axios";
import { URL_BASE } from "../config";
const urlBase = `${URL_BASE}/api`;
// const fecha = "20221004";
// const codigo = "1704";
// const fecha = new Date().toISOString().slice(0, 10).split("-").join("")

export const getVisitas = async (codigoFecha = { codigo: "9999", fecha: "20221014" }) => {
  try {
    const { data } = await axios.get(`${urlBase}/visita/${codigoFecha.codigo}/${codigoFecha.fecha}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getCliente = async (rutcliente, local, fecha) => {
  try {
    const response = await axios.get(
      `${urlBase}/cliente/${rutcliente}/${local}/${fecha}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getNuevoCliente = async (estado = "pendiente") => {
  try {
    const response = await axios.get(
      `${urlBase}/cliente/nuevo/${estado}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getGps = async (rutcliente, local, fecha) => {
  try {
    const response = await axios.get(
      `${urlBase}/gps/${rutcliente}/${local}/${fecha}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getVendedores = async () => {
  try {
    const response = await axios.get(`${urlBase}/vendedores`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCanal = async (canal) => {
  try {
    const response = await axios.get(`${urlBase}/canal/${canal}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCanales = async () => {
  try {
    const response = await axios.get(`${urlBase}/canales`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const customHeader = { headers: { 'Content-Type': 'application/json' } };

export const saveCanal = async (body) => {
  try {
    return await axios.post(`${urlBase}/canal`, body, customHeader)
  } catch (error) {
    console.error(error);
  }
};

export const getPedido = async (codigo, fecha, rutcliente, local) => {
  try {
    const response = await axios.get(`${urlBase}/pedido/${codigo}/${fecha}/${rutcliente}/${local}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPedidosCanal = async (fecha, canal) => {
  // http://186.103.221.179:4110/api/pedidos/canal/20230510/1794-1704
  try {
    const {data} = await axios.get(`${urlBase}/pedidos/canal/${fecha}/${canal}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getPedidos = async (codigo, fecha) => {
  try {
    const {data} = await axios.get(`${urlBase}/pedidos/${codigo}/${fecha}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getDetallePedido = async (codigoPedido) => {
  try {
    const {data} = await axios.get(`${urlBase}/detallepedido/${codigoPedido}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getClientesPorCanal = async (fecha,canal) => {
  try {
    // console.log("getClientesPorCanal========>",fecha,canal)
    const {data} = await axios.get(`${urlBase}/canal/${fecha}/${canal}`);
    //http://186.103.221.179:4110/api/canal/20230420/1704-1794-2311-2495
    return data;
  } catch (error) {
    console.error(error);
  }
};

// export const getSseCanal = async (canal) => {
//   try {
//     const facts = [];
//     const events = new EventSource(`${urlNoHttp}/canales/${canal}`);
//     events.onmessage = (event) => {
//       const parsedData = JSON.parse(event.data);
//       facts.concat(parsedData);
//     };
//   } catch (error) {
//     console.error(error);
//   }
// };
