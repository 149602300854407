import React, { useContext, useState, useEffect } from "react";
import PendingIcon from "@mui/icons-material/Pending";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MapContainer } from "react-leaflet/MapContainer";
import { Marker } from "react-leaflet/Marker";
import { Circle } from "react-leaflet/Circle";
import { CircleMarker } from "react-leaflet/CircleMarker";
import { divIcon } from 'leaflet';
import { Popup } from "react-leaflet/Popup";
// import { Tooltip } from "react-leaflet/Tooltip";
import { TileLayer } from "react-leaflet/TileLayer";
import { DataContext } from "../../provider/DataProvider";
import "./MapView.css";


export default function MapView() {
  const { sales, mapaCirculo, setMapaCirculo } = useContext(DataContext);

  const [newBounds, setNewBounds] = useState([[-35.424557, -71.648537]])
  const [mapa, setMapa] = useState(null);
  const [flag, setFlag] = useState(true);

  // const customIcon = divIcon({ html: "88", iconSize: "auto" });
  //let salesBounds = [[-33.437781, -70.650469]];
  // console.log("14 sales [0]._id",sales[0]._id)
  //const newBounds = sales[0]._id === "1" ? [[-35.424557, -71.648537],[-35.431470, -71.642574] ] : sales.map((e) => [e.lat, e.lng]);
 // const newBounds = sales.map((e) => [e.lat, e.lng]);
  // console.log("###newBounds###", newBounds)
  // useEffect(() => {
  //   setNewBounds(sales.map((e) => [e.lat, e.lng]))
  // }, [sales]);
 
  useEffect(() => {
    if(!flag && sales.length > 0) {
      if (!mapaCirculo) { setMapaCirculo([sales[0].lat, sales[0].lng])}
      console.log("salesdentro useEffect y Flag",sales) // []
      mapa.fitBounds(sales.map((e) => [e.lat, e.lng]));
    }
    setFlag(false)
    // mapa.fitBounds(sales.map((e) => [e.lat, e.lng]));
   // setNewBounds(sales.map((e) => [e.lat, e.lng]));
  }, [sales, mapa, flag]);

  return (
    <>
      {newBounds ? (
        <MapContainer zoom={18} bounds={newBounds} ref={setMapa}>
          <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
          {sales.map((s) => (
            // const kolor = {blue:"No Visitado", green:"Compra"} 
            <Marker 
            key={s._id} 
            position={[s.lat, s.lng]}
            icon={divIcon({ html: `<div class="circle ${(s.status).replace(/\s/g, '').toLowerCase()}">${s.secuencia}</div>`, iconSize: "auto", iconAnchor:[15,15] })}
            >
              <Popup><b>{s.status}</b><p>{s.rutcliente}</p></Popup>
              {/* <Tooltip permanent>{s.secuencia}</Tooltip> */}
            </Marker>
            
          ))}
        { mapaCirculo && 
        <CircleMarker center={mapaCirculo} radius={30}  color="red">
        {/* <Popup>Aca popup</Popup> */}
        {/* <Tooltip permanent>Datos del cliente</Tooltip> */}
        </CircleMarker>
        }
        </MapContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h1" component="h2">
            Esperando
          </Typography>
          <PendingIcon sx={{ fontSize: 100 }} />
        </Box>
      )}
    </>
  );
}
